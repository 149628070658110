<template>
  <div>
    <search-role @submit="submitSearch" />
    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>
    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>
      <span slot="remark" slot-scope="text" class="custom-ellipsis custom-word-break-all">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          {{ text }}
        </a-tooltip>
      </span>
      <span slot="task_authority" slot-scope="text">
        <div class="pre">{{ text }}</div>
      </span>
      <span slot="web_authority" slot-scope="text">
        <div class="pre">{{ text }}</div>
      </span>
      <span slot="order_authority" slot-scope="text">
        <div class="pre">{{ text }}</div>
      </span>
      <span slot="access_platform" slot-scope="text">
        <div class="pre">{{ text }}</div>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record.id)">编辑</a>
          <a @click="showPermissionModal(record.id)" v-if="record.access_platform_slugs.includes('web_manage')">权限设置</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增角色模态框 -->
    <new-role
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑用户模态框 -->
    <edit-role
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <permission
      v-if="isShowPermissionModal"
      :visible.sync="isShowPermissionModal"
      :id="showingPermissionId.toString()"
      :is-system="false"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import { findTenantRoles, updateRoleEffective } from '@/api/role'
import SearchRole from '@/views/roles/tenant/Search'
import { hasPermission } from '@/utils/permission'
import Pagination from '@/components/Pagination'
import { formatBigNumber } from '@/utils/filter'
export default {
  name: 'RoleList',
  components: {
    SearchRole,
    Pagination,
    NewRole: () => import('@/views/roles/tenant/New'),
    EditRole: () => import('@/views/roles/tenant/Edit'),
    Permission: () => import('@/views/roles/Permission')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      isShowPermissionModal: false,
      showingPermissionId: 0,
      editingId: 0, // 正在编辑的记录id
      previewVisible: false,
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '角色名称',
          dataIndex: 'name',
          fixed: 'left',
          width: 150
        },
        {
          title: '状态',
          width: 80,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '上级角色',
          width: 150,
          dataIndex: 'parent_role_name'
        },
        {
          title: '用户数量',
          dataIndex: 'user_count',
          customRender: formatBigNumber
        },
        {
          title: '任务权限',
          dataIndex: 'task_authority',
          width: 180,
          scopedSlots: { customRender: 'task_authority' }
        },
        {
          title: '跟踪订单',
          dataIndex: 'order_authority',
          width: 180,
          scopedSlots: { customRender: 'order_authority' }
        },
        {
          title: 'Web页面权限',
          dataIndex: 'web_authority',
          width: 120,
          scopedSlots: { customRender: 'web_authority' }
        },
        {
          title: '可结算订单',
          dataIndex: 'settle_authority'
        },
        {
          title: '可开单',
          dataIndex: 'appointment_authority'
        },
        {
          title: '可播放视频',
          dataIndex: 'video_authority'
        },
        {
          title: '可登录平台',
          width: 180,
          dataIndex: 'access_platform',
          scopedSlots: { customRender: 'access_platform' }
        },
        {
          title: '说明',
          width: 150,
          scopedSlots: { customRender: 'remark' },
          dataIndex: 'remark'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false,
          width: 120
        },
        {
          title: '操作',
          width: 150,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showPermissionModal(slug) {
      this.showingPermissionId = slug
      this.isShowPermissionModal = true
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTenantRoles(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked

      this.$confirm({
        title: effective ? '确定将角色生效吗?' : '失效后用户将不能选择此角色，确定失效吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateRoleEffective(record.id, { effective: effective })
            .then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            })
            .catch(() => {
              vm.editingEffectiveId = 0
            })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.pre {
  white-space: pre-wrap;
}
</style>
